<template>
  <div class="list">
    <div class="tip">
      <p>温馨提示：请在投保前阅读并确认以下内容</p>
    </div>
    <img src="../assets/insuranceNotice/3.png" alt="" width="100%">
    <div class="btn" v-if="$route.query.channel">
      <p @click="exemptionClause">我已阅读并同意以上内容</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    //跳转责任免除条款
    exemptionClause() {
      this.$router.push({
        path: "/exemptionClause",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  width: 100%;
  height: 44px;
  background: #fff7f4;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color;
    line-height: 21px;
  }
}
.msg {
  margin-top: 16px;
  padding: 0 20px;
  padding-bottom: 100px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  text-align: justify;
}
.btn {
  width: 100%;
  height: 90px;
  background: #fff;
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 0;
  p {
    width: 234px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 23px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
}
</style>